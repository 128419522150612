<template>
	<div>
		<div v-if="!isNewRecord">
			<div>
				<div class="font-weight-bold font-size-36 font-weight-bold text-center">{{ actualRecord.general.code }} {{ actualRecord.general.name }}</div>
				<div class="font-weight-bold font-size-21 text-center">Lider de proyecto: {{ actualRecord.general.leaderName }} {{ actualRecord.general.leaderSurname }}</div>
				<div class="text-center">Presupuesto - {{ numeral(actualRecord.general.budget).format('$0,0.00') }} || Gastado: {{ numeral(actualRecord.general.totalBudget).format('$0,0.00') }}</div>
				<div class="text-center">Invertido el {{ spendBudget }}% del presupuesto total</div>
				<div class="row">
					<div class="col-md-4 offset-md-4 col-sm-12">
						<a-progress type="line" :showInfo="true" :status="spendBudget <= 100 ? 'active' : 'exception'" :percent="spendBudget" />
					</div>
					<div class="col-md-12 text-center" v-if="userData.role == 'super-admin' || userData.role == 'admin'">
						<a-button @click="editProject">Editar proyecto</a-button>
					</div>
				</div>
				<hr class="hrText" data-content="♦" />
			</div>
			<projectsSuppliersComponent />
			<a-modal :visible="modalProjectVisible" title="Registro de Proyecto" :closable="false" width="80%">
				<template slot="footer">
					<a-button key="back" @click="closeEditProject"> Cerrar </a-button>
					<a-button key="submit" class="btn btn-success" @click="saveProject"> Guardar </a-button>
				</template>
				<generalFormComponent :isEmbedded="true" />
			</a-modal>
		</div>
		<div v-else>
			<generalFormComponent />
		</div>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import numeral from 'numeral'
//
import generalFormComponent from './partials/general'
import projectsSuppliersComponent from './partials/suppliers'

export default {
	name: 'projectsDetailComponent',
	components: {
		generalFormComponent,
		projectsSuppliersComponent,
	},
	computed: {
		...mapGetters('projects', ['actualRecord']),
		...mapGetters('auth', ['userData']),
		isNewRecord() {
			return this.$route.params.id == 'new'
		},
		spendBudget() {
			if (!this.isNewRecord) {
				return Number(numeral(numeral(this.actualRecord.general.totalBudget).value() * 100 / numeral(this.actualRecord.general.budget).value()).format('0.0'))
			}
			return ''
		},
	},
	data: () => {
		return {
			modalProjectVisible: false,
		}
	},
	beforeMount() {
		this.$store.dispatch('customers/GET')
		this.$store.dispatch('users/GET')
	},
	mounted() {
		if (!this.isNewRecord) {
			this.$store.dispatch('projects/GET_ONE', {
				project_id: this.$route.params.id,
			})
		}
	},
	destroyed() {
		this.$store.commit('projects/SET_STATE', {
			actualRecord: {},
		})
	},
	methods: {
		numeral,
		editProject() {
			this.modalProjectVisible = true
		},
		closeEditProject() {
			this.modalProjectVisible = false
		},
		saveProject() {
			document.getElementById('generalFormComponentBtn').click()
		},
	},
}
</script>