<template>
	<div>
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-3" v-if="!isNewRecord">
					<a-form-item :label="$t('projectsModule.tableHeaders.key')">
						<a-input :disabled="!isNewRecord" class="text-uppercase" />
					</a-form-item>
				</div>
				<div class="col-md-9">
					<a-form-item :label="$t('projectsModule.tableHeaders.name')">
						<a-input v-decorator="['name', { rules: requiredFileds }]" autocomplete="off" :disabled="userData.role == 'operations'" />
					</a-form-item>
				</div>
				<div class="col-md-4" v-if="!isNewRecord">
					<a-form-item label="Estatus">
						<a-select v-decorator="['status', { rules: requiredFileds }]" :disabled="userData.role == 'operations' || isClosedProject">
							<a-select-option value="in_planning">En planeación</a-select-option>
							<a-select-option value="in_process">En proceso</a-select-option>
							<a-select-option value="closed">Cierre</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-4" v-if="!isNewRecord">
					<a-form-item label="Estatus de facturación">
						<a-select v-decorator="['payment_status', { rules: requiredFileds }]" :disabled="userData.role == 'operations'">
							<a-select-option value="by_bill">Por facturar</a-select-option>
							<a-select-option value="registered">Facturado</a-select-option>
							<a-select-option value="partial">Pago parcial</a-select-option>
							<a-select-option value="complete">Pago completo</a-select-option>
						</a-select>
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Cliente">
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" v-decorator="['customer_id', { rules: requiredFileds }]"
								  :disabled="userData.role != 'super-admin' && userData.role != 'admin'">
							<a-select-option v-for="(element, key) in customersList" :key="key" :value="element.id">{{ element.id }} - {{ element.full_name }}</a-select-option>
						</a-select>
						<div class="row" v-if="userData.role == 'super-admin' || userData.role == 'admin'">
							<div class="col-md-6"><small class="pointer" @click="getCustomers">Recarga</small></div>
							<div class="col-md-6 text-right"><small class="pointer" @click="onOpenCustomerModal">Registro</small></div>
						</div>
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Lider de Proyecto">
						<a-select showSearch option-filter-prop="children" :filter-option="filterOption" v-decorator="['user_id', { rules: requiredFileds }]"
								  :disabled="userData.role != 'super-admin' && userData.role != 'admin'">
							<a-select-option v-for="(element, key) in coordinatorsList" :key="key" :value="element.id">{{ element.name }} {{ element.surname }} ({{ element.role }})</a-select-option>
						</a-select>
						<div class="row" v-if="userData.role == 'super-admin' || userData.role == 'admin'">
							<div class="col-md-6"><small class="pointer" @click="getUsers">Recarga</small></div>
						</div>
					</a-form-item>
				</div>
				<div class="col-md-3">
					<a-form-item label="Presupuesto">
						<a-input v-model="budget" prefix="$" v-money="money" autocomplete="off" :disabled="userData.role != 'super-admin'" />
					</a-form-item>
				</div>
				<div class="col-md-3" v-if="userData.role == 'super-admin'">
					<a-form-item label="Monto de venta (Sin IVA)">
						<a-input v-model="sale_price" prefix="$" v-money="money" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-3" v-if="userData.role == 'super-admin'">
					<a-form-item label="Monto de venta (Con IVA)">
						<a-input :value="taxesBudget" prefix="$" v-money="money" disabled autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-12">
					<a-form-item :label="$t('projectsModule.tableHeaders.description')">
						<a-textarea v-decorator="['description']" :disabled="userData.role == 'operations'" />
					</a-form-item>
				</div>
			</div>
			<div class="row" v-show="!isEmbedded">
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Cerrar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit" id="generalFormComponentBtn">{{ $t('general.save') }}</a-button>
				</div>
			</div>
		</a-form>
		<div>
			<a-modal width="65%" :visible="customerModal.visible" :title="'Nuevo cliente'" :closable="false">
				<template slot="footer">
					<a-button key="back" @click="onCloseCustomerModal"> Cerrar </a-button>
					<a-button key="submit" class="btn btn-success" @click="handleSubmitCustomer"> {{ $t('general.save') }} </a-button>
				</template>
				<customersPartialsGeneral ref="customersPartialsGeneral" :returnData="true" :showActionButtons="false" @close="onCloseCustomerModal" />
			</a-modal>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'
import numeral from 'numeral'
import Swal from 'sweetalert2'
//
import customersPartialsGeneral from '@/components/customers/partials/general'
import moment from 'moment'

export default {
	name: 'projectGeneralForm',
	props: {
		isEmbedded: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		customersPartialsGeneral,
	},
	computed: {
		...mapGetters('projects', ['actualRecord']),
		...mapGetters('customers', ['customersList']),
		...mapGetters('auth', ['userData']),
		...mapGetters('users', ['usersList']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
		requiredFileds() {
			let rules = [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
			return rules
		},
		coordinatorsList() {
			let allowRoles = ['super-admin', 'admin', 'coordinator']
			return this.usersList.filter(e => allowRoles.includes(e.role))
		},
		taxesBudget() {
			return numeral(numeral(this.sale_price ? this.sale_price : 0).value() * 1.16).format('0,0.00')
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			budget: '',
			sale_price: '',
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			customerModal: {
				visible: false,
			},
			isClosedProject: false,
			paymentProjectDate: '',
		}
	},
	async mounted() {
		if (!utilities.objectValidate(this.actualRecord, 'general.id', false) && utilities.objectValidate(this.$route, 'params.id', false) && !this.isNewRecord) {
			// Solicitamos carga de registro
			await this.$store.dispatch('projects/GET_ONE', {
				project_id: this.$route.params.id,
			})
		} else {
			this.setLocalData(this.actualRecord)
		}

		if (!this.customersList.length) {
			this.getCustomers()
		}
		if (!this.coordinatorsList.length) {
			this.getUsers()
		}
	},
	methods: {
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onCancel() {
			this.$router.replace('/projects')
		},
		setLocalData(newValue) {
			if (newValue) {
				// console.log('setLocalData::newValue-->', newValue)
				this.form.setFieldsValue({
					name: utilities.objectValidate(this.actualRecord, 'general.name', ''),
					code: utilities.objectValidate(this.actualRecord, 'general.code', ''),
					customer_id: utilities.objectValidate(this.actualRecord, 'general.customer_id', ''),
					description: utilities.objectValidate(this.actualRecord, 'general.description', ''),
					status: utilities.objectValidate(this.actualRecord, 'general.status', ''),
					payment_status: utilities.objectValidate(this.actualRecord, 'general.payment_status', ''),
					user_id: utilities.objectValidate(this.actualRecord, 'general.user_id', ''),
				})
				setTimeout(() => {
					this.budget = numeral(utilities.objectValidate(this.actualRecord, 'general.budget', 0)).format('0,0.00')
					this.sale_price = numeral(utilities.objectValidate(this.actualRecord, 'general.sale_price', 0)).format('0,0.00')
					this.isClosedProject = this.actualRecord.general.status == 'closed'
				}, 100);
			}
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					// Evaluamos y confirmamos si el usuario está seguro/a de cerrar el proyecto
					let confirmation = true
					if (values.status == 'closed') {
						confirmation = await Swal.fire({
							title: 'Atención',
							html: `¿Deseas realizar cierre del proyecto? Este proceso no puede revertirse`,
							icon: 'warning',
							reverseButtons: true,
							showCancelButton: true,
							confirmButtonText: 'Sí, continuar',
							cancelButtonText: 'Cancelar',
						}).then(async (result) => {
							if (result.isConfirmed) {
								return await Swal.fire({
									title: 'Ingresa fecha de pago',
									html: '<small>En formato DD-MM-AAAA</small>',
									input: 'text',
									inputValue: moment().add(30, 'days').format('DD-MM-YYYY'),
									reverseButtons: true,
									showCancelButton: true,
									confirmButtonText: 'Continuar',
									cancelButtonText: 'Cancelar',
									inputValidator: (value) => {
										if (!value) {
											return 'Es necesario ingresar la fecha estimada de pago'
										}
									},
								}).then((resConfirm) => {
									if (resConfirm.isConfirmed) {
										this.paymentProjectDate = resConfirm.value
										this.isClosedProject = true
										return true
									}
									return false
								})
							}
							this.isClosedProject = false
							return false
						})
					}

					if (confirmation) {
						let payload = {
							...values,
							budget: numeral(this.budget).value(),
							sale_price: numeral(this.sale_price).value(),
						}

						if (this.isNewRecord) {
							this.$store.dispatch('projects/CREATE', payload)
						} else {
							payload.id = this.$route.params.id

							if (this.isClosedProject) {
								payload.paymentProjectDate = this.paymentProjectDate
							}
							this.$store.dispatch('projects/UPDATE', payload)
						}
					}
				}
			})
		},
		getCustomers() {
			this.form.setFieldsValue({
				customer_id: '',
			})

			this.$store.dispatch('customers/GET')
		},
		getUsers() {
			this.form.setFieldsValue({
				user_id: '',
			})
			this.$store.dispatch('users/GET')
		},
		onOpenCustomerModal() {
			this.customerModal.visible = true
		},
		onCloseCustomerModal(payload) {
			this.customerModal.visible = false
			this.$refs.customersPartialsGeneral.cleanFormData()
			this.form.setFieldsValue({
				customer_id: payload.id,
			})
		},
		handleSubmitCustomer() {
			document.getElementById('customersPartialsGeneralSubmit').click()
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				// console.log('actualRecord::newValue-->', newValue)
				if (utilities.objectValidate(newValue, 'general.id', false)) {
					setTimeout(() => {
						this.setLocalData(newValue)
					}, 100)
				}
			},
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>