<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<projectsDetail />
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import projectsDetail from '@/components/projects/detail'

export default {
	name: 'projectsDetailView',
	components: {
		projectsDetail,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('projects', ['spinnerLoader', 'spinnerLoaderLabel']),
		isNewRecord() {
			return this.$route.params.id == 'new' ? true : false
		},
	},
	mounted() {
		if (this.isNewRecord) {
			//
		} else {
			//
		}
	},
}
</script>