<template>
	<div>
		<div class="row">
			<div class="col-md-4 ">
				<a-button class="btn btn-info" icon="plus" @click="onOpenModal('record')">Nuevo proveedor</a-button>
			</div>
			<div class="col-md-8 text-right">
				<a-button class="btn btn-info" icon="search" @click="onSearch">Buscar proveedor</a-button>
				<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
			</div>
			<div class="col-md-12 pt10">
				<a-table :columns="columns" :dataSource="actualRecord.suppliers.data" :footer="null" :pagination="false">
					<div slot="full_name" slot-scope="record" class="text-left">
						{{ record.full_name }}
					</div>
					<div slot="budget" slot-scope="record" class="text-right">
						{{ numeral(record.totalBudget).format('$0,0.00') }}
					</div>
					<div slot="email" slot-scope="record" class="text-left">
						{{ record.email }}
						<br>
						{{ record.phone }}
					</div>
					<div slot="action" slot-scope="record">
						<a-tooltip>
							<template slot="title">
								Detalle servicios
							</template>
							<a-button size="small" icon="menu-unfold" @click="servicesDetail(record.id)" />
						</a-tooltip>
						<a-tooltip>
							<template slot="title">
								Eliminar proveedor
							</template>
							<a-button class="ml5" size="small" icon="minus-circle" @click="toggleRecord(record.id)" />
						</a-tooltip>
						<a-tooltip>
							<template slot="title">
								Notificar cierre
							</template>
							<a-button class="ml5" type="info" size="small" icon="mail" @click="particularNotification(record.id)" />
						</a-tooltip>
						<a-tooltip v-if="false">
							<template slot="title">
								Notificaciones
							</template>
							<a-button class="ml5" type="info" size="small" icon="warning" @click="showAllNotifications(record.id)" />
						</a-tooltip>
					</div>
				</a-table>
			</div>
		</div>
		<div class="row pt20">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
			</div>
		</div>
		<a-modal :visible="modalVisible.services" title="Servicios del Proveedor" :closable="false" width="95%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('services')"> Cerrar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onConfirmServices"> Guardar </a-button>
			</template>
			<div class="row">
				<div class="col-md-6">
					<table class="table table-bordered table-condensed table-striped">
						<thead>
							<tr>
								<th class="text-center" colspan="4">
									<h3 class="text-muted pt15">Listado de servicios</h3>
									<a-button class="btn btn-success" icon="plus" size="small" @click="addOtherService">Añadir servicio</a-button>
								</th>
							</tr>
							<tr>
								<th class="text-center" width="15%">Cantidad</th>
								<th class="text-center">Descripción</th>
								<th class="text-center" width="15%">Costo unitario</th>
								<th class="text-center" width="15%">Importe</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(element, index) in servicesList" :key="index">
								<td class="text-center">
									<a-input v-model="element.quantity" class="text-center" />
								</td>
								<td class="text-left">
									<a-input v-model="element.description" />
								</td>
								<td class="text-right">
									<a-input v-model="element.unit_cost" prefix="$" class="text-right" v-money="money" />
								</td>
								<td class="text-right">
									{{ numeral(numeral(element.unit_cost).value() * numeral(element.quantity).value()).format('$0,0.00') }}
									<div style="color: red; line-height: 5px;"><small class="pointer" @click="deleteElement(index)">Eliminar</small></div>
								</td>
							</tr>
							<tr>
								<td class="text-right" colspan="3">Subtotal</td>
								<td class="text-right">{{ numeral(getSubtotal).format('$0,0.00') }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-6">
					<table class="table table-bordered table-condensed table-striped">
						<thead>
							<tr>
								<th class="text-center" colspan="4">
									<h3 class="text-muted pt15">Listado de facturas</h3>
								</th>
							</tr>
							<tr>
								<th class="text-center">
									Tipo
								</th>
								<th width="20%" class="text-center">
									Fecha de creación
								</th>
								<th width="15%" class="text-center">
									Subtotal
								</th>
								<th width="15%" class="text-center"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(element, index) in filesList" :key="index">
								<td>Pago #{{ filesList.length - index }}</td>
								<td class="text-right">{{ moment(element.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</td>
								<td class="text-right">{{ numeral(element.subtotal).format('$0,0.00') }}</td>
								<td class="text-center">
									<a-button @click="getSecureURL(element.id, element.mimetype == 'text/xml' ? 'download' : 'show')" :icon="element.mimetype == 'text/xml' ? 'download' : 'file'"
											  size="small"></a-button>
									<a-button icon="dollar" class="ml5" size="small" @click="createODC(element.id, `Pago #${filesList.length - index}`)" v-if="!element.purchase_order_id"></a-button>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="text-right">Subtotal facturado</td>
								<td class="text-right">{{ numeral(getSubtotalFiles).format('$0,0.00') }}</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</a-modal>
		<a-modal :visible="modalVisible.list" title="Listado de Proveedores" :closable="false" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('list')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onConfirmSuppliers"> Confirmar </a-button>
			</template>
			<a-table :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columnsSuppliers" :data-source="suppliersListFiltered">
				<div slot="nationality" slot-scope="record">
					{{ record.nationality == 'foreign' ? 'Extranjero' : 'Nacional' }}
				</div>
				<div slot="phone" slot-scope="record">
					{{ record.phone }}
				</div>
				<div slot="action" slot-scope="record">
					<a-button size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="toggleRecord(record.id)" />
				</div>
			</a-table>
		</a-modal>
		<a-modal :visible="modalVisible.record" title="Registro de Proveedores" :closable="false" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('record')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onSaveSupplier"> Guardar </a-button>
			</template>
			<generalFormComponent ref="generalFormComponent" :isEmbedded="true" :projectID="actualRecord.general.id" @close="onCloseModal('record')" />
		</a-modal>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Archivo adjunto" :closable="true" @cancel="handleCancelModalFile" v-if="modalFileVisible">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar archivo</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import moment from 'moment'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'
import { services } from '@/constants'
import generalFormComponent from '@/components/suppliers/partials/general'
import fileViewerComponent from '@/components/fileViewer'

export default {
	name: 'projectsSuppliersComponent',
	directives: {
		money: VMoney,
	},
	components: {
		generalFormComponent,
		fileViewerComponent,
	},
	computed: {
		...mapGetters('projects', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		...mapGetters('suppliers', ['suppliersListFiltered']),
		...mapGetters('auth', ['userData']),
		getSubtotal() {
			let amount = 0
			if (this.servicesList && this.servicesList.length) {
				this.servicesList.map(currentValue => {
					let quantity = numeral(currentValue.quantity).value()
					let unit_cost = numeral(currentValue.unit_cost).value()
					amount += quantity * unit_cost
				})
			}
			return amount
		},
		getSubtotalFiles() {
			let total = 0
			this.filesList.map(e => {
				console.log('getSubtotalFiles-->', e);
				total += e.subtotal
			})
			return total
		},
	},
	data() {
		return {
			pagination: {
				current: 0,
				total: 0,
				pageSize: 0,
			},
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			columns: [
				{
					title: this.$t('suppliersModule.tableHeaders.name'),
					scopedSlots: { customRender: 'full_name' },
					align: 'center',
				},
				{
					title: 'Total por pagar',
					scopedSlots: { customRender: 'budget' },
					align: 'center',
					width: '15%',
				},
				{
					title: `${this.$t('suppliersModule.tableHeaders.email')} / ${this.$t('suppliersModule.tableHeaders.phones')}`,
					scopedSlots: { customRender: 'email' },
					align: 'center',
					width: '25%',
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '20%',
				},
			],
			columnsSuppliers: [
				{
					title: this.$t('suppliersModule.tableHeaders.id'),
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: this.$t('suppliersModule.tableHeaders.name'),
					dataIndex: 'full_name',
					key: 'full_name',
				},
				{
					title: this.$t('suppliersModule.tableHeaders.nacionality'),
					scopedSlots: { customRender: 'nationality' },
				},
				{
					title: this.$t('suppliersModule.tableHeaders.email'),
					dataIndex: 'email',
					key: 'email',
				},
				{
					title: this.$t('suppliersModule.tableHeaders.phones'),
					scopedSlots: { customRender: 'phone' },
				},
			],
			modalServicesVisible: false,
			selectedRowKeys: [],
			servicesList: [],
			services,
			modalVisible: {
				list: false,
				record: false,
				services: false,
			},
			theSupplier: '',
			fileURL: '',
			modalFileVisible: false,
			filesList: [],
		}
	},
	mounted() {
		this.pagination = {
			current: utilities.objectValidate(this.actualRecord, 'suppliers.pagination.currentPage', 1),
			total: utilities.objectValidate(this.actualRecord, 'suppliers.pagination.total', 0),
			pageSize: utilities.objectValidate(this.actualRecord, 'suppliers.pagination.perPage', 25),
		}
	},
	methods: {
		numeral,
		moment,
		initModule() {
			this.$store.dispatch('projects/GET_ONE', {
				project_id: this.$route.params.id,
			})
		},
		onCancel() {
			this.$router.replace('/projects')
		},
		toggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('projects/TOGGLE_SUPPLIERS', {
						supplier_ids: [id],
						project_id: this.actualRecord.general.id,
					}).then(() => {
						this.$store.dispatch('projects/GET_ONE', {
							project_id: this.$route.params.id,
						})
					})
				}
			})
		},
		onSearch() {
			Swal.fire({
				title: 'Búsqueda de proveedores',
				input: 'text',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Continuar",
				cancelButtonText: "Cancelar",
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Es necesario ingresar texto')
						}
						resolve()
					})
				},
			}).then(response => {
				if (response.isConfirmed) {
					this.$store
						.dispatch('suppliers/SEARCH', {
							searchText: response.value,
							project_id: this.actualRecord.general.id,
						})
						.then((response) => {
							//
							this.onOpenModal('list')
						})
				}
			})
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		onConfirmSuppliers() {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: '¿Desea continuar con la asignación de los proveedores seleccionados?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					let supplier_ids = this.selectedRowKeys.map((index) => {
						return this.suppliersListFiltered[index].id
					})

					this.$store
						.dispatch('suppliers/CREATE_RELATIONSHIP', {
							supplier_ids,
							project_id: this.actualRecord.general.id,
						})
						.then(() => {
							this.onCloseModal('list')
							this.initModule()
						})
				}
			})
		},
		particularNotification(supplier_id) {
			let record = this.actualRecord.suppliers.data.find(e => e.id == supplier_id)

			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: `Se notificará vía email a <b>${record.full_name}</b> sobre el cierre del proyecto. ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('projects/PARTICULAR_NOTIFICATION_CLOSED_PROJECT', {
						project_id: this.actualRecord.general.id,
						supplier_id,
					})
				}
			})
		},
		addServices(id) {
			this.theSupplier = id
			this.onOpenModal('services')
		},
		getServiceDescription(id) {
			let theService = undefined
			if (id > 0) {
				theService = this.services.find(e => e.id == id)
				return theService.description
			}
			return ''
		},
		onConfirmServices() {
			let payload = _.cloneDeep(this.servicesList)
			payload = payload.filter(e => e)

			if (!payload.length) {
				Swal.fire({
					title: 'Servicios',
					html: `No se han ingresado datos para los servicios correspondientes`,
					icon: 'warning',
				})
				return false
			}

			let errors = []
			payload.forEach(e => {
				if (e.quantity == '') {
					errors.push(`- Se requiere cantidad en ${e.description}`)
				}
				if (e.unit_cost == '' || numeral(e.unit_cost).value() == 0) {
					errors.push(`- Se requiere costo unitario en ${e.description}`)
				}
			})

			if (errors.length) {
				Swal.fire({
					title: 'Servicios',
					html: errors.join('<br>'),
					icon: 'error',
					confirmButtonText: 'Ok',
				})
				return false
			}

			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: `Se asociarán los servicios indicados en la tabla anterior ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					//
					payload = {
						elements: [...payload],
						project_id: this.$route.params.id,
						supplier_id: this.theSupplier,
					}
					this.$store.dispatch('projects/ADD_SUPPLIER_SERVICES', payload).then(() => {
						this.onCloseModal('services')
						this.initModule()
					})
				}
			})
		},
		addOtherService() {
			let inputOptions = {}
			this.services.forEach(e => {
				if (!inputOptions[e.id]) {
					inputOptions[e.id] = e.description
				}
				inputOptions['-1'] = 'Otro'
			})

			Swal.fire({
				title: 'Selecciona servicio',
				input: 'select',
				inputOptions,
				inputPlaceholder: 'Selecciona servicio',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Continuar',
				cancelButtonText: this.$t('general.cancel'),
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (!value) {
							resolve('Selecciona una opción')
						} else {
							resolve()
						}
					})
				},
			}).then(res => {
				if (res.isConfirmed) {
					this.servicesList.push({
						service_id: res.value,
						quantity: '',
						unit_cost: '',
						description: `${res.value > 0 ? this.services.find(e => e.id == res.value).description : 'Otro'} - `,
					})
				}
			})
		},
		onOpenModal(modal) {
			this.modalVisible[modal] = true
		},
		onCloseModal(modal) {
			this.modalVisible[modal] = false
			this.theSupplier = ''
			this.servicesList = []

			switch (modal) {
				case 'record':
					this.$refs.generalFormComponent.cleanData()
					break;
			}
		},
		onSaveSupplier() {
			document.getElementById('onSaveSupplierBtn').click()
		},
		servicesDetail(supplier_id) {
			//
			this.theSupplier = supplier_id

			this.$store.dispatch('projects/GET_SUPPLIER_SERVICES', {
				project_id: this.$route.params.id,
				supplier_id,
			}).then(response => {
				// console.log('response-->', response);
				let servicesList = []
				this.filesList = _.cloneDeep(response.files.filter(e => e.mimetype == 'application/pdf'))

				response.servicesList.forEach(e => {
					servicesList.push({
						...e,
						quantity: numeral(e.quantity).value(),
						unit_cost: numeral(e.unit_cost).format('0,0.00'),
					})
				})

				this.servicesList = _.cloneDeep(servicesList)
				this.onOpenModal('services')
			})
		},
		deleteElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar la partida <b>"${this.servicesList[index].description}"</b>?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let servicesList = []
					this.servicesList.forEach((e, i) => {
						if (i != index) {
							servicesList.push(e)
						}
					})
					setTimeout(() => {
						this.servicesList = _.cloneDeep(servicesList)
					}, 10);
				}
			})
		},
		handleCancelModalFile() {
			this.fileURL = ''
			this.modalFileVisible = false
		},
		getSecureURL(id) {
			this.$store
				.dispatch('files/GET_FILE_URL', {
					entity: 'suppliers_files',
					id,
				})
				.then((response) => {
					this.fileURL = response.data.url
					this.modalFileVisible = true
				})
		},
		createODC(id, description) {
			let theRecord = this.filesList.find(e => e.id == id)
			// console.log('theRecord-->', theRecord);
			// return false

			Swal.fire({
				title: 'Atención',
				html: `Se va a generar una ODC lista para pagar. ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let payload = {
						total: numeral(theRecord.subtotal + theRecord.total_impuestos_trasladados).value(),
						elements: [{
							description: `${description} del proyecto "${this.actualRecord.general.name}"`,
							measurement_unit: 'service',
							quantity: 1,
							unit_cost: theRecord.subtotal + theRecord.total_impuestos_trasladados,
						}],
						supplier_id: this.theSupplier,
						payment_method_index: 0,
						payment_method_data: {},
						blocked: true,
						status: 1,
						fromSupplier: true,
						fileID: theRecord.id,
						fileGroup: theRecord.file_group,
						total_impuestos_retenidos: theRecord.total_impuestos_retenidos,
						total_impuestos_trasladados: theRecord.total_impuestos_trasladados,
					}

					// console.log('payload-->', payload);
					this.$store.dispatch('purchaseOrders/CREATE', { payload }).then(() => {
						this.servicesDetail(this.theSupplier)
					})
				}
			})
		},
		showAllNotifications() {
			//
			console.log('showAllNotifications-->');
		},
	},
}
</script>
<style lang="scss" scoped>
.table-condensed {
	td {
		padding: 5px !important;
		background-color: #ffffff;
	}
}
</style>
